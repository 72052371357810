<template>
  <div class="grafico">
    <Bar
      :chartData="arrTeste"
      :chartData2="arrTeste2"
      :options="options"
      label="Rentabilidade acumulada"
      label2="Rentabilidade mensal"
    />
  </div>
</template>

<script>
import Bar from "@/components/graph/DoubleBar.vue";
export default {
  data() {
    return {};
  },
  components: {
    Bar,
  },
  props:{
      arrTeste:{type: Array},
      arrTeste2:{type: Array},
      options:{type: Object},
  }
};
</script>

<style lang="scss" scoped>
</style>