var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { staticClass: "switch" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.clicked,
          expression: "clicked",
        },
      ],
      attrs: { type: "checkbox", checked: "" },
      domProps: {
        checked: Array.isArray(_vm.clicked)
          ? _vm._i(_vm.clicked, null) > -1
          : _vm.clicked,
      },
      on: {
        change: [
          function ($event) {
            var $$a = _vm.clicked,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.clicked = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.clicked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.clicked = $$c
            }
          },
          _vm.changeSwitch,
        ],
      },
    }),
    _c("span", { staticClass: "slider round" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }