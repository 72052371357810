<template>
  <v-container fluid class="container">
    <div class="top-base">
      <div class="data">
        <div>
          <span>{{ $hoje }}</span>
        </div>
        <div>
          <v-img
            src="../../../assets/icons/calendar_icon.svg"
            class="imagem_calendar"
          ></v-img>
        </div>
      </div>
    </div>

    <div class="cards">
      <div class="card">
        <span class="title">Saldo Bruto</span>
        <span class="valor">R$ 1.369.771,86</span>
      </div>
      <div class="card">
        <span class="title">Saldo Liquido</span>
        <span class="valor">R$ 1.369.771,86</span>
      </div>
      <div class="card">
        <span class="title">Rendimento</span>
        <span class="valor">R$ 1.369.771,86</span>
      </div>
      <div class="card">
        <span class="title">Rendimento Histórico</span>
        <span class="valor">12,63%</span>
      </div>
    </div>

    <div class="graficos-rentabilidade">
      <v-card class="card-graficos">
        <div class="btns-rentabilidade">
          <div
            class="btn-novo-ativo"
            :class="{ clicado: rentabilidadeOuResultado }"
            @click="novoOuExistente"
          >
            <span>Rentabilidade</span>
          </div>
          <div
            class="btn-ativo-existente"
            :class="{ clicado: !rentabilidadeOuResultado }"
            @click="novoOuExistente"
          >
            <span>Resultado Financeiro</span>
          </div>
        </div>
        <div class="top-grafico" v-if="rentabilidadeOuResultado">
          <div class="top">
            <div class="title">
              <span>Rentabilidade da carteira</span>
            </div>
            <div class="btns-ativos">
              <div class="btn-cdi">
                <SwitchButton @changeSwitch="changeSwitch" />
                <span>CDI</span>
              </div>

              <div class="btn-ipca">
                <SwitchButton @changeSwitch="changeSwitch" />
                <span>IPCA</span>
              </div>

              <div class="btn-dolar">
                <SwitchButton @changeSwitch="changeSwitch" />
                <span>DÓLAR</span>
              </div>

              <div class="btn-ibovespa">
                <SwitchButton @changeSwitch="changeSwitch" />
                <span>IBOVESPA</span>
              </div>
            </div>
          </div>
          <div class="grafico">
            <RentabilidadeGrafico
              :arrTeste="arrTeste"
              :arrTeste2="arrTeste2"
              :options="options"
            />
          </div>
        </div>

        <div class="top-grafico" v-else>
          <div class="top">
            <div class="title">
              <span>Resultado Financeiro</span>
            </div>
          </div>
          <div class="grafico">
            <ResultadoFinanceiroGrafico
              :arrTeste="arrTeste"
              :arrTeste2="arrTeste2"
              :options="options"
              :plugins="plugins"
            />
          </div>
        </div>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as Mock from "@/components/mock/mock.js";
import SwitchButton from "@/components/SwitchButton.vue";
import RentabilidadeGrafico from "./components/RentabilidadeGrafico.vue";
import ResultadoFinanceiroGrafico from "./components/ResultadoFinanceiroGrafico.vue";
export default {
  data() {
    return {
      arrTeste: [],
      arrTeste2: [],
      today: "",
      rentabilidadeOuResultado: true,
      movimentacao: {},
      plugins:[ChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        elements: {
          line: {
            fill: false,
            tension: 0.4,
          },
        },
        legend: {
          labels: {
            usePointStyle: true,
          },
          position: "top",
          padding: 10,
        },
        // plugins:[ChartDataLabels],
        scales: {
        xAxes: [{
            gridLines: {
                display:false
            }
        }],
          yAxes: [
            {
              beginAtZero: true,
              ticks: {
                min: 0,
                max: 100,
                callback: function (value) {
                  return "R$" + value ;
                },
              },
            },
          ],
    }
      },
    };
  },
  created() {
    for (let d of Mock.mock1) {
      let data = moment(d.date, "DDMMYYYY").format("MMM/YYYY");

      this.arrTeste.push({ data, valors: d });
    }

    for (let d of Mock.mock2) {
      let data = moment(d.date, "DDMMYYYY").format("MMM/YYYY");

      this.arrTeste2.push({ data, valors: d });
    }
  },
  components: {
    SwitchButton,
    RentabilidadeGrafico,
    ResultadoFinanceiroGrafico,
  },
  computed: {
    $hoje() {
      let hoje = Date.now();
      return moment(hoje).format("DD/MM/YYYY");
    },
  },
  methods: {
    novoOuExistente() {
      this.rentabilidadeOuResultado == true
        ? (this.rentabilidadeOuResultado = false)
        : (this.rentabilidadeOuResultado = true);
    },
    showMovimentacao(e) {
      this.rentabilidadeOuResultado = !e.show;
      this.movimentacao = e;

      setTimeout(() => {
        this.movimentacao = {};
      }, 1000); //necessario zerar a movimentação... para fazer o fluxo de entrada e saida das telas
    },
    changeSwitch(e) {
      this.switch1 = e;

      if (this.switch1) {
        for (let d of Mock.mock3) {
          //aqui eu boto novas informações
          let data = moment(d.date, "DDMMYYYY").format("MMM/YYYY");
          this.arrTeste2.push({ data, valors: d });
        }
      } else {
        for (let d of Mock.mock3) {
          for (const [index, value] of this.arrTeste2.entries()) {
            //aqui eu retiro essas as informações novas que entraram no if
            if (d.id === value.valors.id) {
              this.arrTeste2.splice(index, 1);
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.container {
  /* border: 1px solid red; */
  padding: 0;
}
.top-base {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  flex-wrap: wrap;
  /* border: 1px solid; */
}
.data {
  display: flex;
}
.data > div {
  margin: 0 0.2rem;
}
.data .imagem_calendar {
  filter: invert(70%) sepia(26%) saturate(8645%) hue-rotate(195deg)
    brightness(105%) contrast(100%);
}

/* CARDS DO TOPO */
.cards {
  /* padding: 1rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100vw;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.cards .card {
  background: white;
  padding: 0.8rem;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  flex: 1 1 300px;
  margin: 0.5rem;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05);
}
.card .title {
  font-size: 15px !important;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.15px;
  padding-bottom: 0.5rem;
}
.card .valor {
  font-weight: normal;
  font-size: 25px;
  line-height: 37px;
}

/* GRAFICOS DE RENTABILIDADE */
.graficos-rentabilidade {
  /* border: 1px solid red; */
  border-radius: 20px;
  overflow: hidden;
}
.card-graficos {
  padding: 1.5rem;
}
.top-grafico {
  /* border: 1px solid; */
}
.top-grafico > div {
  padding: 1rem 0;
}
.top-grafico .top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;
}
.top-grafico .top .title {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
  color: #000000;
}
.top-grafico .bottom {
  display: flex;
  flex-wrap: wrap;
}
.top-grafico .bottom > div {
  margin: 0.2rem;
  display: flex;
  align-items: center;
}
.btns-ativos {
  display: flex;
  flex-wrap: wrap;
}
.btns-ativos > div {
  margin: 0 0.3rem;
}
.btns-ativos > div span {
  padding: 0 1rem;
}
.grafico {
}

/* BOTOES DO GRAFICO DE RENTABILIDADE */
.btns-rentabilidade > div {
  margin-right: 0.8rem;
  border-radius: 30px;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btns-rentabilidade > div span{
    text-align: center;
}
.btn-novo-ativo {
  border: 2px solid rgba(73, 73, 73, 0.5);
  color: rgba(73, 73, 73, 0.5);
}
.btn-ativo-existente {
  border: 2px solid rgba(73, 73, 73, 0.5);
  color: rgba(73, 73, 73, 0.5);
}
.clicado {
  background: rgba(73, 73, 73, 0.5);
  border: none;
  color: white;
}
.btns-rentabilidade {
  /* border: 1px solid red; */
  display: flex;
}
</style>