var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-container", { staticClass: "container", attrs: { fluid: "" } }, [
    _c("div", { staticClass: "top-base" }, [
      _c("div", { staticClass: "data" }, [
        _c("div", [_c("span", [_vm._v(_vm._s(_vm.$hoje))])]),
        _c(
          "div",
          [
            _c("v-img", {
              staticClass: "imagem_calendar",
              attrs: {
                src: require("../../../assets/icons/calendar_icon.svg"),
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "cards" }, [
      _c("div", { staticClass: "card" }, [
        _c("span", { staticClass: "title" }, [_vm._v("Saldo Bruto")]),
        _c("span", { staticClass: "valor" }, [_vm._v("R$ 1.369.771,86")]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c("span", { staticClass: "title" }, [_vm._v("Saldo Liquido")]),
        _c("span", { staticClass: "valor" }, [_vm._v("R$ 1.369.771,86")]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c("span", { staticClass: "title" }, [_vm._v("Rendimento")]),
        _c("span", { staticClass: "valor" }, [_vm._v("R$ 1.369.771,86")]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c("span", { staticClass: "title" }, [_vm._v("Rendimento Histórico")]),
        _c("span", { staticClass: "valor" }, [_vm._v("12,63%")]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "graficos-rentabilidade" },
      [
        _c("v-card", { staticClass: "card-graficos" }, [
          _c("div", { staticClass: "btns-rentabilidade" }, [
            _c(
              "div",
              {
                staticClass: "btn-novo-ativo",
                class: { clicado: _vm.rentabilidadeOuResultado },
                on: { click: _vm.novoOuExistente },
              },
              [_c("span", [_vm._v("Rentabilidade")])]
            ),
            _c(
              "div",
              {
                staticClass: "btn-ativo-existente",
                class: { clicado: !_vm.rentabilidadeOuResultado },
                on: { click: _vm.novoOuExistente },
              },
              [_c("span", [_vm._v("Resultado Financeiro")])]
            ),
          ]),
          _vm.rentabilidadeOuResultado
            ? _c("div", { staticClass: "top-grafico" }, [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("span", [_vm._v("Rentabilidade da carteira")]),
                  ]),
                  _c("div", { staticClass: "btns-ativos" }, [
                    _c(
                      "div",
                      { staticClass: "btn-cdi" },
                      [
                        _c("SwitchButton", {
                          on: { changeSwitch: _vm.changeSwitch },
                        }),
                        _c("span", [_vm._v("CDI")]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "btn-ipca" },
                      [
                        _c("SwitchButton", {
                          on: { changeSwitch: _vm.changeSwitch },
                        }),
                        _c("span", [_vm._v("IPCA")]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "btn-dolar" },
                      [
                        _c("SwitchButton", {
                          on: { changeSwitch: _vm.changeSwitch },
                        }),
                        _c("span", [_vm._v("DÓLAR")]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "btn-ibovespa" },
                      [
                        _c("SwitchButton", {
                          on: { changeSwitch: _vm.changeSwitch },
                        }),
                        _c("span", [_vm._v("IBOVESPA")]),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "grafico" },
                  [
                    _c("RentabilidadeGrafico", {
                      attrs: {
                        arrTeste: _vm.arrTeste,
                        arrTeste2: _vm.arrTeste2,
                        options: _vm.options,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _c("div", { staticClass: "top-grafico" }, [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("span", [_vm._v("Resultado Financeiro")]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "grafico" },
                  [
                    _c("ResultadoFinanceiroGrafico", {
                      attrs: {
                        arrTeste: _vm.arrTeste,
                        arrTeste2: _vm.arrTeste2,
                        options: _vm.options,
                        plugins: _vm.plugins,
                      },
                    }),
                  ],
                  1
                ),
              ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }