<script>
import { Bar } from "vue-chartjs";
export default {
  extends: Bar,
  props: {
    label: { type: String },
    label2: { type: String },
    chartData: { type: Array },
    options: { type: Object },
    chartData2: { type: Array },
  },
  computed: {
    $dados() {
      return this.chartData2;
    },
  },
  methods: {
    renderizarChart() {
      const dates = this.chartData.map((d) => d.data).reverse();
      const valors = this.chartData.map((d) => d.valors.percent).reverse();
      const valors2 = this.$dados.map((d) => d.valors.percent).reverse();

      this.renderChart(
        {
          labels: dates,
          datasets: [
            {
              label: this.label,
              backgroundColor: "#F3A03C",
              borderColor: "#F3A03C",
              data: valors2,
              pointRadius: 5,
            },
            {
              label: this.label2,
              data: valors,
              backgroundColor: "#579AF2",
              borderRadius: 10
            },
          ],
        },
        this.options
      );
    },
  },
  mounted() {
    this.renderizarChart();
  },
  watch:{
    chartData2(){
      this.renderizarChart()
    }
  }
};
</script>