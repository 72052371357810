var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grafico" },
    [
      _c("Bar", {
        attrs: {
          chartData: _vm.arrTeste,
          chartData2: _vm.arrTeste2,
          options: _vm.options,
          plugins: _vm.plugins,
          label: "Rentabilidade acumulada",
          label2: "Rentabilidade mensal",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }